import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba0551f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base_input flex" }
const _hoisted_2 = { class: "input_wrapper w100 flex" }
const _hoisted_3 = ["type", "disabled"]
const _hoisted_4 = { class: "material-symbols-outlined dark_text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass([{
          rounded: _ctx.rounded,
          input_with_right_icon: _ctx.rounded && _ctx.rightIcon,
          slim: _ctx.slim,
        }, "mpx light_text"]),
        type: _ctx.type,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        disabled: _ctx.readonly
      }, null, 10, _hoisted_3), [
        [_vModelDynamic, _ctx.value]
      ])
    ]),
    (_ctx.rightIcon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["right_icon flex content_center align_center", {
        right_icon_rounded: _ctx.rounded,
        pointer: _ctx.rightIconAction,
        disabled: _ctx.rightIconAction && !_ctx.value,
      }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rightIconClicked()))
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.rightIconText), 1)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}