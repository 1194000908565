import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5707cdb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_AppMenu = _resolveComponent("AppMenu")!
  const _component_GameDepositModal = _resolveComponent("GameDepositModal")!
  const _component_audio_player = _resolveComponent("audio-player")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_AppToolbar),
      _createVNode(_component_AppMenu),
      _createElementVNode("audio", {
        loop: "",
        autoplay: "",
        class: "background_music",
        onPlay: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backgroundMusicStart()))
      }, [
        _createElementVNode("source", {
          src: _ctx.$domain.baseDomain + '/assets/audios/background_music.mp3',
          type: "audio/mpeg"
        }, null, 8, _hoisted_1)
      ], 32),
      (_ctx.showDepositModal)
        ? (_openBlock(), _createBlock(_component_GameDepositModal, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_audio_player, { "file-name": "click.mp3" }),
      _createVNode(_component_router_view, { key: "view" }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, null, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                class: _normalizeClass({
            mobile_app_wrapper: _ctx.$screen.isSmall,
          })
              }, null, 8, ["class"]))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}