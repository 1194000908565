/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from "@metaplex-foundation/beet";
export type FinishGameArgs = {
  reveledLimit: beet.bignum;
  reveledSalt: beet.bignum;
  timestamp: beet.bignum;
};

/**
 * @category userTypes
 * @category generated
 */
export const finishGameArgsBeet = new beet.BeetArgsStruct<FinishGameArgs>(
  [
    ["reveledLimit", beet.u64],
    ["reveledSalt", beet.u64],
    ["timestamp", beet.u64],
  ],
  "FinishGameArgs",
);
