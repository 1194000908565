import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45f58385"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile_toolbar flex align_center dark_background" }
const _hoisted_2 = { class: "menu_wrapper flex align_center content_space_between" }
const _hoisted_3 = { class: "flex align_center" }
const _hoisted_4 = { class: "logo_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ConnectButton = _resolveComponent("ConnectButton")!
  const _component_AppLogo = _resolveComponent("AppLogo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MenuIcon),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.$route.query.tab)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              dark: "",
              icon: "message",
              class: "icon srm message_icon",
              onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleChat()))
            }))
          : _createCommentVNode("", true),
        (!_ctx.$store.getters.isLoggedIn)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.$screen.isMediumAndUp)
                ? (_openBlock(), _createBlock(_component_ConnectButton, {
                    key: 0,
                    onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.connect()))
                  }))
                : (_openBlock(), _createBlock(_component_Icon, {
                    key: 1,
                    dark: "",
                    icon: "wallet",
                    class: "icon wallet_icon",
                    onClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.connect()))
                  }))
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AppLogo, {
        onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/')))
      })
    ])
  ]))
}