/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from "@metaplex-foundation/beet";
import * as web3 from "@solana/web3.js";
import {
  StartGameStatusArgs,
  startGameStatusArgsBeet,
} from "../types/StartGameStatusArgs";

/**
 * @category Instructions
 * @category StartGame
 * @category generated
 */
export type StartGameInstructionArgs = {
  args: StartGameStatusArgs;
};
/**
 * @category Instructions
 * @category StartGame
 * @category generated
 */
export const startGameStruct = new beet.BeetArgsStruct<
  StartGameInstructionArgs & {
    instructionDiscriminator: number[] /* size: 8 */;
  }
>(
  [
    ["instructionDiscriminator", beet.uniformFixedSizeArray(beet.u8, 8)],
    ["args", startGameStatusArgsBeet],
  ],
  "StartGameInstructionArgs",
);
/**
 * Accounts required by the _startGame_ instruction
 *
 * @property [_writable_, **signer**] signer
 * @property [_writable_] crank
 * @property [_writable_] game
 * @category Instructions
 * @category StartGame
 * @category generated
 */
export type StartGameInstructionAccounts = {
  signer: web3.PublicKey;
  crank: web3.PublicKey;
  game: web3.PublicKey;
  systemProgram?: web3.PublicKey;
  rent?: web3.PublicKey;
  anchorRemainingAccounts?: web3.AccountMeta[];
};

export const startGameInstructionDiscriminator = [
  249, 47, 252, 172, 184, 162, 245, 14,
];

/**
 * Creates a _StartGame_ instruction.
 *
 * @param accounts that will be accessed while the instruction is processed
 * @param args to provide as instruction data to the program
 *
 * @category Instructions
 * @category StartGame
 * @category generated
 */
export function createStartGameInstruction(
  accounts: StartGameInstructionAccounts,
  args: StartGameInstructionArgs,
  programId = new web3.PublicKey(
    "2yKYJeX7NDF6gSHANJqnxHuAuHuBx5Qf3s9oAmMpWzxh",
  ),
) {
  const [data] = startGameStruct.serialize({
    instructionDiscriminator: startGameInstructionDiscriminator,
    ...args,
  });
  const keys: web3.AccountMeta[] = [
    {
      pubkey: accounts.signer,
      isWritable: true,
      isSigner: true,
    },
    {
      pubkey: accounts.crank,
      isWritable: true,
      isSigner: false,
    },
    {
      pubkey: accounts.game,
      isWritable: true,
      isSigner: false,
    },
    {
      pubkey: accounts.systemProgram ?? web3.SystemProgram.programId,
      isWritable: false,
      isSigner: false,
    },
    {
      pubkey: accounts.rent ?? web3.SYSVAR_RENT_PUBKEY,
      isWritable: false,
      isSigner: false,
    },
  ];

  if (accounts.anchorRemainingAccounts != null) {
    for (const acc of accounts.anchorRemainingAccounts) {
      keys.push(acc);
    }
  }

  const ix = new web3.TransactionInstruction({
    programId,
    keys,
    data,
  });
  return ix;
}
